import { useTheme } from '@emotion/react';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Link } from '../../styled/Link';
import { Container } from '../Container';
import masterCardImg from '../../../public/img/mastercard.png';
import mirImg from '../../../public/img/mir.png';
import visaImg from '../../../public/img/visa.png';
import LockSVG from '../../../public/img/lock.svg';
import LogoFooterSVG from '../../../public/img/logo-footer.svg';
import BlogLogoSVG from '../../../public/img/logo-blog.svg';
import { FooterList, FooterTitle } from './styled';
import { Icon } from '../../icon/icon';
import {
  DEVICE_SCREENS,
  DEVICE_SCREENS_MAX_STRING,
  DEVICE_SCREENS_MIN_STRING,
} from '../../../lib/consts';
import { City, Page, Social } from '../../../models';
import { Contact } from '../../../models/contact';
import { BLOG_DOMAIN } from '../../../lib/api/apiUrl';

const SITE_SECTIONS = {
  company: 'company',
  clients: 'for_clients',
  help: 'help',
};

const CONCACT_TYPES = {
  email: 'email',
  telephone: 'телефон',
  openingHours: 'график',
  name: 'название',
  address: 'адрес',
};

export function Footer({
  pageList,
  socials,
  socialsLinks,
  contactList,
  currentCity,
  className,
}: {
  pageList: { results: Page[] };
  socials: { results: Social[] };
  socialsLinks: { results: Social[] };
  contactList: { results: Contact[] };
  currentCity: City;
  className: string;
}) {
  const theme = useTheme();
  const currentYear = DateTime.now().year;
  const [isMobileMode, setIsMobileMode] = useState(false);
  useEffect(() => {
    setIsMobileMode(window.innerWidth < DEVICE_SCREENS.sm);
  }, []);
  const companyPages = pageList?.results.filter(
    (page) => page.site_section === SITE_SECTIONS.company
  );
  const clientsPages = pageList?.results.filter(
    (page) => page.site_section === SITE_SECTIONS.clients
  );
  const helpPages = pageList?.results.filter(
    (page) => page.site_section === SITE_SECTIONS.help
  );

  return (
    <footer
      className={className}
      css={{
        padding: '40px 0',
        backgroundColor: theme.colors.bgFooter,
        color: theme.colors.white,
        fontWeight: 500,
      }}
    >
      <Container
        css={{
          paddingLeft: '8px',
          paddingRight: '8px',
          [DEVICE_SCREENS_MIN_STRING.xxl]: {
            maxWidth: '1280px !important',
          },
          [DEVICE_SCREENS_MAX_STRING.md]: {
            display: 'flex',
            justifyContent: 'center',
          },
        }}
        className='container'
      >
        <div
          // row footer__content
          css={{
            display: 'flex',
            marginRight: '-8px',
            marginLeft: '-8px',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            [DEVICE_SCREENS_MAX_STRING.lg]: {
              display: 'grid',
              fontSize: 14,
              marginRight: '-10.5px',
              marginLeft: '-10.5px',
            },
            [DEVICE_SCREENS_MAX_STRING.md]: {
              fontSize: 12.6,
              maxWidth: '100%',
            },
            [DEVICE_SCREENS_MAX_STRING.sm]: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
          }}
        >
          <div
            // footer__logo
            css={{
              fontFamily: 'Golos',
              paddingRight: theme.constants.padding,
              paddingLeft: '30px',
              display: 'flex',
              flex: '1 0 0',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexGrow: 1,
              flexBasis: 0,
              order: 0,
              [DEVICE_SCREENS_MAX_STRING.lg]: {
                alignItems: 'center',
                justifyContent: 'center',
                order: 7,
                gridColumnStart: 1,
                gridColumnEnd: 3,
              },
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <div
              // bank_info
              css={{
                width: '100%',
                marginTop: 18,
                [DEVICE_SCREENS_MAX_STRING.lg]: {
                  marginTop: 8,
                },
                [DEVICE_SCREENS_MAX_STRING.sm]: {
                  width: '50%',
                },
              }}
            >
              <ul
                // bank_info__logos
                css={{
                  display: 'flex',
                  gap: 12,
                  listStyle: 'none',
                  justifyContent: 'flex-start',
                  padding: 0,
                  margin: 0,
                  marginBottom: '20px',
                  li: {
                    overflow: 'hidden',
                    borderRadius: '3px',
                    height: '24px',
                    backgroundColor: theme.colors.white,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  [DEVICE_SCREENS_MAX_STRING.lg]: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  },
                }}
              >
                <li>
                  <img
                    src={masterCardImg.src}
                    loading='lazy'
                    title='mastercard'
                    alt='mastercard'
                  />
                </li>
                <li>
                  <img
                    src={mirImg.src}
                    css={{ width: '90%' }}
                    loading='lazy'
                    title='mir'
                    alt='mir'
                  />
                </li>
                <li css={{ width: 38 }}>
                  <img
                    width='38px'
                    height='17.2px'
                    src={visaImg.src}
                    loading='lazy'
                    title='visa'
                    alt='visa'
                  />
                </li>
              </ul>
              <ul
                // bank_info__features
                css={{
                  margin: 0,
                  padding: 0,
                  listStyle: 'none',
                  li: {
                    position: 'relative',
                    margin: '12px 0',
                    svg: {
                      position: 'absolute',
                      top: 0,
                    },
                  },
                }}
              >
                <li>
                  <LockSVG
                    css={{
                      left: '-30px',
                    }}
                    loading='lazy'
                    title='lock icon'
                    alt='lock icon'
                  />
                  <p>
                    Безопасные платежи картой любого банка. Защита персональных
                    данных.
                  </p>
                </li>
              </ul>
            </div>
            <div
              // logo logo_footer
              css={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <LogoFooterSVG
                css={{
                  width: '120px',
                  height: '20px',
                  fill: theme.colors.white,
                }}
              />
            </div>
            <span
              css={{
                fontSize: '10px',
              }}
            >
              © 2015-{currentYear} Gorbilet.com
            </span>
          </div>
          <div
            // footer__divider footer__divider_vertical
            css={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              flex: '0 0 auto',
              width: '2px',
              margin: '0 90px',
              padding: 0,
              order: 1,
            }}
          />
          <div
            css={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              height: '2px',
              display: 'none',
              order: 1,
              [DEVICE_SCREENS_MAX_STRING.lg]: {
                display: 'block',
                width: '60%',
                margin: '40px auto',
                gridColumnStart: 1,
                gridColumnEnd: 3,
                order: 6,
              },
            }}
            // footer__divider footer__divider_horizontal
          />
          {!!companyPages.length && (
            <div
              // footer__company
              css={{
                fontFamily: 'Golos',
                margin: '0 auto',
                paddingRight: theme.constants.padding,
                paddingLeft: theme.constants.padding,
                flex: '1 0 0',
                order: 2,
                [DEVICE_SCREENS_MAX_STRING.lg]: {
                  width: '100%',
                  gridColumnStart: 1,
                  gridColumnEnd: 2,
                },
                [DEVICE_SCREENS_MAX_STRING.sm]: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: 30,
                },
              }}
            >
              <FooterTitle>Компания</FooterTitle>
              <FooterList>
                {companyPages.map((page) =>
                  page.external_link ? (
                    <li key={`CompanyPageLi__${page.id}`}>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        key={`CompanyPageLink__${page.id}`}
                        href={page.external_link}
                      >
                        {page.title}
                      </a>
                    </li>
                  ) : (
                    <li key={`CompanyPageLi__${page.id}`}>
                      <Link
                        key={`CompanyPageLink__${page.id}`}
                        href={
                          page.slug === 'vidzhety' ||
                          page.slug === 'person-catalog'
                            ? ['', page.slug, ''].join('/')
                            : ['', currentCity.url, page.slug, ''].join('/')
                        }
                      >
                        {page.title}
                      </Link>
                    </li>
                  )
                )}
              </FooterList>
            </div>
          )}
          {!!helpPages.length && (
            <div
              // footer_help
              css={{
                fontFamily: 'Golos',
                margin: '0 auto',
                paddingRight: theme.constants.padding,
                paddingLeft: theme.constants.padding,
                flex: '1 0 0',
                order: 3,
                [DEVICE_SCREENS_MAX_STRING.lg]: {
                  width: '100%',
                  gridColumnStart: 2,
                  gridColumnEnd: 3,
                },
                [DEVICE_SCREENS_MAX_STRING.sm]: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: 30,
                },
              }}
            >
              <FooterTitle>Помощь</FooterTitle>
              <FooterList>
                {helpPages.map((page) => (
                  <li key={`HelpPageLi__${page.id}`}>
                    <Link
                      key={`HelpPageLink__${page.id}`}
                      href={
                        page.slug === 'faq'
                          ? ['', page.slug, ''].join('/')
                          : ['', 'pages', page.slug, ''].join('/')
                      }
                    >
                      {page.title}
                    </Link>
                  </li>
                ))}
              </FooterList>
              <div
              // footer__help-social
              >
                {!!socials?.results.length && !isMobileMode && (
                  <div
                    // social-question
                    css={{
                      minWidth: '184px',
                    }}
                  >
                    <div>
                      <div
                        // social-question__head
                        css={{
                          cursor: 'pointer',
                          display: 'flex',
                          color: theme.colors.white,
                          marginBottom: '8px',
                          ...theme.mixins.fonts.fontBody,
                          svg: { stroke: theme.colors.white },
                        }}
                      >
                        Задать вопрос
                      </div>
                      <div
                        // social-question__body
                        css={{
                          svg: {
                            width: '22px',
                            height: '22px',
                          },
                          display: 'flex',
                          flexWrap: 'wrap',
                          'a:not(:last-child)': {
                            marginRight: '12px',
                            marginBottom: '6px',
                          },
                        }}
                      >
                        {socials.results.map((social) => (
                          <Link
                            rel='noopener noreferrer'
                            target='_blank'
                            key={social.id}
                            href={social.url}
                          >
                            <Icon iconId={social.type} />
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!!clientsPages.length && (
            <div
              // footer_clients
              css={{
                fontFamily: 'Golos',
                margin: '0 auto',
                paddingRight: theme.constants.padding,
                paddingLeft: theme.constants.padding,
                flex: '1 0 0',
                order: 4,
                [DEVICE_SCREENS_MAX_STRING.sm]: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: 30,
                },
              }}
            >
              <FooterTitle>Клиентам</FooterTitle>
              <FooterList>
                {clientsPages.map((page) => (
                  <li key={`ClientsPageLi__${page.id}`}>
                    <Link
                      key={`ClientsPageLink__${page.id}`}
                      href={['', 'pages', page.slug].join('/')}
                    >
                      {page.title}
                    </Link>
                  </li>
                ))}
              </FooterList>
            </div>
          )}
          <div
            itemScope
            itemType='https://schema.org/Organization'
            // footer_contacts
            css={{
              fontFamily: 'Golos',
              margin: '0 auto',
              paddingRight: theme.constants.padding,
              paddingLeft: theme.constants.padding,
              flex: '1 0 0',
              order: 5,
              [DEVICE_SCREENS_MAX_STRING.lg]: {
                width: '100%',
              },
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                order: 4,
              },
            }}
          >
            <meta
              itemProp='url'
              content={`https://${process.env.DEPLOY_DOMAIN}`}
            />
            <meta
              itemProp='logo'
              content={`https://${process.env.DEPLOY_DOMAIN}/apple-touch-icon-114x114.png/`}
            />
            <meta itemProp='sameAs' content='https://vk.me/gorbilet' />
            <meta
              itemProp='sameAs'
              content='https://t.me/Gorbilet_otdel_zaboty_bot'
            />
            <FooterTitle> Контакты</FooterTitle>
            <FooterList>
              {contactList?.results.map((contact) => (
                <li key={`ClientsPageLi__${contact.id}`}>
                  {contact.type === CONCACT_TYPES.email && (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <a
                      href={`mailto:${contact.information}`}
                      itemProp='email'
                      dangerouslySetInnerHTML={{ __html: contact.information }}
                    />
                  )}
                  {contact.type === CONCACT_TYPES.telephone && (
                    <span
                      itemScope
                      itemType='https://schema.org/ContactPoint'
                      itemProp='contactPoint'
                    >
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <a
                        href={`tel:${contact.information}`}
                        dangerouslySetInnerHTML={{
                          __html: contact.information,
                        }}
                      />
                      <meta
                        itemProp='telephone'
                        content={contact.information}
                      />
                      <meta itemProp='contactType' content='customer service' />
                    </span>
                  )}
                  {contact.type === CONCACT_TYPES.openingHours && (
                    <span
                      itemScope
                      itemType='http://schema.org/EntertainmentBusiness'
                    >
                      <span css={{ display: 'none' }} itemProp='name'>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        ООО "Горбилет"
                      </span>
                      <span css={{ display: 'none' }} itemProp='address'>
                        наб. канала Грибоедова, 5
                      </span>
                      <span css={{ display: 'none' }} itemProp='telephone'>
                        +7 (812) 603 46 33
                      </span>
                      <time
                        dateTime='Mo-Su 10:00-22:00'
                        itemProp='openingHours'
                        dangerouslySetInnerHTML={{
                          __html: contact.information,
                        }}
                      />
                    </span>
                  )}
                  {contact.type === CONCACT_TYPES.name && (
                    <div
                      itemProp='name'
                      dangerouslySetInnerHTML={{
                        __html: contact.information,
                      }}
                    />
                  )}
                  {contact.type === CONCACT_TYPES.address && (
                    <div
                      itemProp='address'
                      itemScope
                      itemType='http://schema.org/PostalAddress'
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: contact.information,
                        }}
                      />
                      <meta itemProp='name' content={contact.information} />
                    </div>
                  )}
                </li>
              ))}
              {!!socials?.results.length && isMobileMode && (
                <div
                  // social-question
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <div
                      // social-question__head
                      css={{
                        marginTop: 11,
                        cursor: 'pointer',
                        display: 'flex',
                        color: theme.colors.white,
                        marginBottom: '8px',
                        ...theme.mixins.fonts.fontBody,
                        fontFamily: 'Montserrat',
                        fontSize: 18,
                        lineHeight: '22px',
                        fontWeight: 700,
                        svg: { stroke: theme.colors.white },
                      }}
                    >
                      Задать вопрос
                    </div>
                    <div
                      // social-question__body
                      css={{
                        svg: {
                          width: '22px',
                          height: '22px',
                        },
                        display: 'flex',
                        flexWrap: 'wrap',
                        'a:not(:last-child)': {
                          marginRight: '12px',
                          marginBottom: '6px',
                        },
                      }}
                    >
                      {socials.results.map((social) => (
                        <a key={`SocialAnchor__${social.id}`} href={social.url}>
                          <Icon iconId={social.type} />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </FooterList>
            <div
              // footer__social
              css={{
                marginTop: '12px',
                [DEVICE_SCREENS_MAX_STRING.sm]: {
                  display: 'none',
                  order: 0,
                },
              }}
            >
              <FooterTitle
                css={{
                  display: 'none',
                  marginBottom: '12px',
                }}
              >
                Мы в соцсетях
              </FooterTitle>
              <div
                // social-icons
                css={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  [DEVICE_SCREENS_MAX_STRING.sm]: {
                    justifyContent: 'center',
                  },
                }}
              >
                <div
                  // body
                  css={{
                    svg: {
                      width: '22px',
                      height: '22px',
                    },
                    display: 'flex',
                    flexWrap: 'wrap',
                    'a:not(:last-child)': {
                      marginRight: '12px',
                      marginBottom: '6px',
                    },
                  }}
                >
                  {socialsLinks.results.map((social) => (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      key={social.id}
                      href={social.url}
                      aria-label={social.title}
                    >
                      <Icon iconId={social.type} />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            css={{
              fontFamily: 'Golos',
              margin: '0 auto',
              paddingRight: theme.constants.padding,
              paddingLeft: theme.constants.padding,
              flex: '1 0 0',
              order: 5,
              [DEVICE_SCREENS_MAX_STRING.lg]: {
                width: '100%',
              },
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                order: 0,
                marginBottom: 30,
              },
            }}
          >
            <a
              href={[BLOG_DOMAIN, 'blog', currentCity.url].join('/')}
              target='_blank'
              rel='noreferrer'
              aria-label='ссылка на сайт Горбилет блог'
            >
              <BlogLogoSVG />
            </a>

            <p>Актуальные материалы о культурной жизни города</p>
          </div>
          <div
            // footer__social
            css={{
              marginTop: '12px',
              display: 'none',
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                order: 0,
                marginBottom: 30,
              },
            }}
          >
            <FooterTitle
              css={{
                fontFamily: 'Golos',
                display: 'none',
                marginBottom: '12px',
              }}
            >
              Мы в соцсетях
            </FooterTitle>
            <div
              // social-icons
              css={{
                display: 'flex',
                justifyContent: 'flex-start',
                [DEVICE_SCREENS_MAX_STRING.sm]: {
                  justifyContent: 'center',
                },
              }}
            >
              <div
                // body
                css={{
                  svg: {
                    width: '22px',
                    height: '22px',
                  },
                  display: 'flex',
                  flexWrap: 'wrap',
                  'a:not(:last-child)': {
                    marginRight: '12px',
                    marginBottom: '6px',
                  },
                }}
              >
                {socialsLinks.results.map((social) => (
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    key={`SocialLinkIcon__${social.id}`}
                    href={social.url}
                    aria-label={social.title}
                  >
                    <Icon iconId={social.type} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
