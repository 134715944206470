import styled from '@emotion/styled';
import { DEVICE_SCREENS_MAX_STRING } from '../../../lib/consts';

export const FooterTitle = styled.div(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  letterSpacing: '1px',
  color: theme.colors.red,
  textTransform: 'uppercase',
  marginBottom: '1em',
  [DEVICE_SCREENS_MAX_STRING.sm]: {
    display: 'inline-block',
  },
}));

export const FooterList = styled.ul(({ theme }) => ({
  padding: 0,
  margin: 0,
  [DEVICE_SCREENS_MAX_STRING.sm]: {
    display: 'inline-block',
  },
  listStyle: 'none',
  '&>li': {
    marginBottom: '0.45em',
    [DEVICE_SCREENS_MAX_STRING.sm]: {
      textAlign: 'center',
    },
    '&>div': {
      marginBottom: '0.45em',
    },
    '&>a': {
      textDecoration: 'none',
      color: theme.colors.white,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));
